import $ from 'jquery';
import {
  $Doc,
  $Win
} from "../utils/jQueryGlobals";
import { gsapSingleton, scrollTriggerSingleton } from "../gsap";
const gsap = gsapSingleton();
const ScrollTrigger = scrollTriggerSingleton();

gsap.registerPlugin(ScrollTrigger);

$Win.on('scroll', () => {
  if ($Doc.scrollTop() > 190) {
    $('header').addClass('no-top');
  } else {
    $('header').removeClass('no-top');
  }
});

let hamburgher = $('.hamburger');

$(hamburgher).on('click', function () {
  if ($(this).hasClass('is-active')) {
    $(this).removeClass('is-active');
    $('#menu-items').hide();
    $('html, body').removeClass('open-menu no-scroll');
  } else {
    $(this).addClass('is-active');
    $('#menu-items').show();
    $('html, body').addClass('open-menu no-scroll');

    let titoliHeader = document.querySelectorAll('.menu-item > a');


    titoliHeader.forEach(element => {
      gsap.from(element, {
        y: '110%',
        delay: 0.3,
        opacity: 0,
        stagger: { each: 0.1 },
      });
    });

  }
})

$('.menu-item-has-children').on('click', function (event) {
  // Controlla se il clic è direttamente sul submenu aperto
  if (!$(event.target).closest('.sub-menu').length) {
    let subMenu = $(this).find('.sub-menu');
    subMenu.toggleClass('open');
    // Aggiungi la classe "open" all'elemento genitore quando il submenu viene aperto
    $(this).toggleClass('open', subMenu.hasClass('open'));
  }
  // Prevenire la propagazione per evitare comportamenti indesiderati
  event.stopPropagation();
});

// Aggiungi un listener anche per il document per chiudere il submenu quando si clicca fuori
$(document).on('click', function (event) {
  if (!$(event.target).closest('.menu-item-has-children').length) {
    $('.sub-menu.open').removeClass('open');
    // Rimuovi la classe "open" dall'elemento genitore quando si chiude il submenu
    $('.menu-item-has-children').removeClass('open');
  }
});
