import $ from 'jquery'
import Component from  "../models/Component"

export default class Gmultimap extends Component {
    static _initializers = []

    constructor(element, context) {
        super(element, context)

        this.markers = [];

        this.mapStyle = [
            {
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#f5f5f5"
                }
              ]
            },
            {
              "elementType": "labels.icon",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#616161"
                }
              ]
            },
            {
              "elementType": "labels.text.stroke",
              "stylers": [
                {
                  "color": "#f5f5f5"
                }
              ]
            },
            {
              "featureType": "administrative.land_parcel",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#bdbdbd"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#eeeeee"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#757575"
                }
              ]
            },
            {
              "featureType": "poi.business",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "poi.park",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#e5e5e5"
                }
              ]
            },
            {
              "featureType": "poi.park",
              "elementType": "labels.text",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "poi.park",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#9e9e9e"
                }
              ]
            },
            {
              "featureType": "road",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#ffffff"
                }
              ]
            },
            {
              "featureType": "road.arterial",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#757575"
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#dadada"
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#616161"
                }
              ]
            },
            {
              "featureType": "road.local",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#9e9e9e"
                }
              ]
            },
            {
              "featureType": "transit.line",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#e5e5e5"
                }
              ]
            },
            {
              "featureType": "transit.station",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#eeeeee"
                }
              ]
            },
            {
              "featureType": "water",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#c9c9c9"
                }
              ]
            },
            {
              "featureType": "water",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#9e9e9e"
                }
              ]
            }
          ];

        Gmultimap._initializers.push(() => {
            this.initGoogleMaps()
        })

        $(() => {
            this.makers = this.getData('markers');
            this.loadGoogleMaps()
        })
    }

    initGoogleMaps() {
        // crea un'istanza della mappa di Google Maps
        this.map = new google.maps.Map(this.element.get(0), {
            zoom: 8,
            minZoom: 3,
            center: {
                lat: 45.18345,
                lng: 9.12345
            },
            scrollwheel: false,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            rotateControl: false,
            styles: this.mapStyle,
        })

        // aggiungi i markers alla mappa
        this.addMarkers(this.makers)

        this.element.parent().removeClass('loading')
    }

    addMarkers(markers) {
        markers.forEach((marker) => {
            const position = {
                lat: marker.lat,
                lng: marker.lng
            };

            const googleMarker = new google.maps.Marker({
                position: position,
                map: this.map,
                url: marker.url,
                icon: window.location.origin + '/wp-content/uploads/2024/09/pin-1.png',
                label: {
                  text: marker.count.toString(), // Usa l'indice come numerino
                  color: "white",               // Colore del numero
                  fontSize: "16px",             // Dimensione del testo
                  fontWeight: "bold",
                  className: "label-maps"           // Peso del testo
              }
            });

            const infoWindowContent = '<div class="popup-marker"><h3>' + marker.title + '</h3><p>' + marker.description + '</p><a href="' + marker.url + '" target="_blank">Website</a></div>';

            const infoWindow = new google.maps.InfoWindow({
                content: infoWindowContent
            });

            // googleMarker.addListener('click', () => {
            //     infoWindow.open(this.map, googleMarker);
            // });

            this.markers.push(googleMarker);
        });
    }

    onMarkerClick(url) {
        window.open(url, '_blank')
    }

    loadGoogleMaps() {
        // aggiungi lo script di Google Maps API se non è già presente
        if (!window.initGoogleMaps) {
            window.initGoogleMaps = () => {
                Gmultimap.initAllGoogleMaps()
            }
            const scriptSrc = 'https://maps.googleapis.com/maps/api/js?key=' + window._gTheme_.google_maps_api_key + '&callback=initGoogleMaps'
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.src = scriptSrc
            document.body.appendChild(script)
        }
    }

    static initAllGoogleMaps() {
        Gmultimap._initializers.forEach(function (initializer) {
            initializer()
        })
    }
}
