import SwiperSlider from "../components/abstract/SwiperSlider";
import $ from 'jquery'

export default class SliderTestimoniaze extends SwiperSlider {
  constructor(element, context) {
    super(element, context);
    $(() => {

      this.options = {
        breakpoints: {
          700: {
            slidesPerView: 2,
          },
          1000: {
            slidesPerView: 2.5,
            spaceBetween:90
          }
        },
        centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: 20,
        navigation: {
          nextEl: ".testimonianze .swiper-button-next",
          prevEl: ".testimonianze .swiper-button-prev",
        },
        loop: true,
        autoplay: {
          delay: 8000,
          disableOnInteraction: false,
        },
        speed: 1000
      };

        $(() => {
          this.init()
        })
    });
  }
}