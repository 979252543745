import Registry from "./models/Registry";
import Slider from "./components/Slider";
import SliderPartner from "./components/SliderPartner";
import SliderTestimonianze from "./components/SliderTestimonianze";
import GMap from "./components/GMap";
import Gmultimap from "./components/Gmultimap";
import Modal from "./components/Modal";
import SliderEventi from "./components/SliderEventi";
import './components/Fadein';
import './components/MainMenu';


class App {
  constructor(context) {
    this.registry = new Registry();
    this.setupUnloadScroll();
    this.registerComponents();
    this.initController(context);
    this.patchIos();
    this.setvh();
  }

  initController(contextData) {
    const Context = this.registry.getContext(contextData);
    new Context(contextData);
  }

  registerComponents() {
    this.registry
      .addComponent('Slider', Slider)
      .addComponent('SliderPartner', SliderPartner)
      .addComponent('SliderTestimonianze', SliderTestimonianze)
      .addComponent('SliderEventi', SliderEventi)
      .addComponent('Modal', Modal)
      .addComponent('GMap', GMap)
      .addComponent('Gmultimap', Gmultimap)
    ;
  }

  patchIos() {
    window.addEventListener('resize', () => this.appHeight())
    this.appHeight()
  }

  appHeight() {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  setvh() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  
  setupUnloadScroll() {
    setTimeout(function() {

      if (window.location.hash) {
        const targetElement = document.querySelector(window.location.hash);
        if (targetElement) {
          targetElement.scrollIntoView();
        }
      } else {
        window.scrollTo(0, 0);
      }
    }, 400);
  }
  
}

window.AppInit = function(context) {
    new App(context);
};
