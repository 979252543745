import SwiperSlider from "../components/abstract/SwiperSlider";
import $ from 'jquery'

export default class Slider extends SwiperSlider {
  constructor(element, context) {
    super(element, context);
    $(() => {

      this.options = {
        slidesPerView: 1,
        spaceBetween: 40,
        navigation: {
          nextEl: ".hero .swiper-button-next",
          prevEl: ".hero .swiper-button-prev",
        },
        loop: true,
        autoplay: {
          delay: 8000,
          disableOnInteraction: false,
        },
        speed: 1000
      };

        $(() => {
          this.init()
        })
    });
  }
}