import SwiperSlider from "../components/abstract/SwiperSlider";
import $ from 'jquery'

export default class SliderPartner extends SwiperSlider {
  constructor(element, context) {
    super(element, context);
    $(() => {
      
      this.options = {
        breakpoints: {
            700: {
              slidesPerView: 5,
              spaceBetween:90,
              autoplay: false
            },
            1000: {
              slidesPerView: 5,
              spaceBetween:90,
              autoplay: false
            },
            
          },
        autoplay: {
            disableOnInteraction: false,
            delay: 1
        },
        speed: 1000,
        slidesPerView: 3,
        spaceBetween: 40,
        navigation: {
          nextEl: ".partner .swiper-button-next",
          prevEl: ".partner .swiper-button-prev",
        },
        loop: true,
      };

        $(() => {
          this.init()
        })
    });
  }
}