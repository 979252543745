import $ from 'jquery';
import Component from '../models/Component';

export default class Modal extends Component {
  constructor(element, context) {
    super(element, context);

    const modal = this.element;
    const closeModal = this.element.find('#close-modal');
    const openModal = $('#open-modal');
    const body = $('body');
    console.log(openModal);

    openModal.on( "click", ()=> {
      modal.addClass('flex');
      modal.removeClass('hidden');
      body.addClass('no-scroll');
    });

    closeModal.on( "click", ()=> {
      modal.removeClass('flex');
      modal.addClass('hidden');
      body.toggleClass('no-scroll');
    });


    $(() => {
      this.init();
    });
  }

  // closeModal() {
  //   const close = $(this.element).attr('data-close');
  //   const modal = $(this.element).attr('data-modal');
  //   $(close).on('click', function () {
  //     $(modal).removeClass('active');
  //     if(!$('body').hasClass('open-menu')) {
  //       $('html, body').removeClass('no-scroll');
  //     }

  //     // Aggiungi una classe 'inactive' per avviare l'animazione di chiusura
  //     $(modal).addClass('inactive');
  //     setTimeout(function(){
  //       $(modal).hide();
  //     }, 350)

  //   });
  // }

  // openModal() {
  //   const modal = $(this.element).attr('data-modal');
  //   $(this.element).on('click', function () {
  //     $(modal).show();
  //     if ($(modal).hasClass('active')) {
  //       $(modal).removeClass('active');
  //       $('html, body').removeClass('no-scroll');
  //       // Rimuovi la classe 'active' e 'inactive' per invertire l'animazione
  //       $(modal).removeClass('inactive');
  //     } else {
  //       $(modal).addClass('active');
  //       $('html, body').addClass('no-scroll');
  //       // Rimuovi la classe 'inactive' per avviare l'animazione di apertura
  //       $(modal).removeClass('inactive');
  //     }
  //   });
  // }

  init() {
    // this.openModal();
    // this.closeModal();
  }
}
