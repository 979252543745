import SwiperSlider from "../components/abstract/SwiperSlider";
import $ from 'jquery'

export default class SliderEventi extends SwiperSlider {
  constructor(element, context) {
    super(element, context);
    $(() => {

      this.options = {
        pagination: {
            el: ".swiper-pagination",
            dynamicBullets: true,
        },
        breakpoints: {
            700: {
                slidesPerView: 2.5
            },
            1000: {
                slidesPerView: 3.5
            }
        },
        centeredSlides: true,
        slidesPerView: 1.2,
        spaceBetween: 20,
        loop: true,
        autoplay: {
          disableOnInteraction: false,
        },
        speed: 1000
      };

        $(() => {
          this.init()
        })
    });
  }
}